import { Link } from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import './Section3.css'
import photo1 from '../../img/suits/white/5.jpg'
import photo3 from '../../img/russo7.jpg'

function Section3() {
    const {t,i18n} = useTranslation()

    return (
        <section className="section3">
            <div className='section3div'>
                <div className="photo">
                    <img src={photo1} alt="MarioRusso" />
                </div>
                <div className="photoLink">
                    <Link to='gallery'>{t('home.browsegallery')}</Link>
                </div>
                <div className="photo">
                    <img src={photo3} alt="MarioRusso" />
                </div>
            </div>
        </section>
    )
}

export default Section3