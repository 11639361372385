import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './Footer.css'

function Footer(){
  const {t,i18n} = useTranslation()

    return (
        <footer>
            <div className='container'>
                <div className='footer-cols'>
                    <div className='footer-col-1'>
                    <Link to='/' className='footer-mario'>
                        Mario Russo
                    </Link>
                    <p className='footer-text'>{t('footer.title')}</p>
                    </div>
                    <div className='footer-col-2'>
                        <h2 className='footer-title'>{t('footer.quickLink')}</h2>
                        <ul className='footer-ul'>
                            <li>
                                <Link to='/'>
                                    {t('menu.home')}
                                </Link>
                            </li>
                            <li>
                                <Link to='/fabrics'>
                                {t('menu.fabrics')}
                                </Link>
                            </li>
                            <li>
                                <Link to='/collections'>
                                {t('menu.gallery')}
                                </Link>
                            </li>
                            <li>
                                <Link to='/stores'>
                                {t('menu.stores')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='footer-col-3'>
                        <h2 className='footer-title'>{t('footer.contact')}</h2>
                        <ul className='footer-ul'>
                            <li>
                                <p className='footer-tel'>{t('footer.phone')}: <Link to='tel:+994504308484'>+994 50 430 84 84</Link> <br /><Link to='tel:+994994308484'>+994 99 430 84 84</Link></p>
                            </li>
                            <li>
                                <p className='footer-email'>{t('footer.email')}: <Link to='mailto:info@mario-russo.com'>info@mario-russo.com</Link></p>
                            </li>
                            <li>
                                <Link target='_blank' to='https://www.instagram.com/mariorusso/'><i className='fa-brands fa-instagram footer-socialmedia'></i></Link>
                                <Link target='_blank' to='https://wa.me/+994504308484'><i className='fa-brands fa-whatsapp footer-socialmedia mx-3'></i></Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='footer-bottom'>
                    <p className='footer-copyright'>© 2023 | {t('footer.develop1')}<Link target='_blank' to='https://www.4digital.az/'>4digital.az</Link>{t('footer.develop2')} | {t('footer.rights')}</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer