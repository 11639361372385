import { useTranslation } from 'react-i18next'
import './FabricsIntro.css'

function FabricsIntro() {
  const {t, i18n} = useTranslation()

    return (
        <section className='fabricsIntro py-5'>
            <div>
                <div className='photo1'></div>
                <div className='desc1'>
                    <h2 className='my-3'>{t('fabrics.title1')}</h2>
                    <p>{t('fabrics.desc1')}</p>
                </div>
            </div>
            <div id='fabricsIntrodiv2'>
                <div className='desc2'>
                    <h2 className='my-3'>{t('fabrics.title2')}</h2>
                    <p>{t('fabrics.desc2')}</p>
                </div>
                <div className='photo2'></div>
            </div>
        </section>
    )
}

export default FabricsIntro