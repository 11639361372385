import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function ScrollToTop(){
  
  const { pathname } = useLocation()
  useEffect(() => {
    if(pathname!=="/"){window.scrollTo(0, 600);}
  }, [pathname])
}

export default ScrollToTop