import { useRef } from 'react'
import {useTranslation} from 'react-i18next'
import emailjs from 'emailjs-com'
import './Appointment.css'

function Appointment() {
    const {t,i18n} = useTranslation()
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm(
          'service_9yw05pr',
          'template_98igbgl',
          form.current,
          'oapneQEI2BIMHmoL0',
        ).then(
          result=>{
            console.log(result)
            const submitButton = document.getElementById('submitButton')
            submitButton.classList.add('disabled')
          },
          error=>{alert("There was a error while sending your message.Please contact with Mario Russo with other options or refresh the page and try again.")}
        )
        e.target.reset()
    }

    return (
        <section className='appointment py-5'>
            <div>
                <div className='text-white my-3'>
                    <h2>{t('home.appointment.h2')}</h2>
                    <p>{t('home.appointment.p1')}</p>
                    <p>{t('home.appointment.p2')}</p>
                </div>
                <div className='card my-3'>
                    <form className='form' ref={form} onSubmit={sendEmail}>
                        <div className='group'>
                            <input placeholder='‎' type='text' name='name' required='required' />
                            <label htmlFor='name'>{t('home.appointment.name')}</label>
                        </div>
                        <div className='group'>
                            <input placeholder='‎' type='email' id='email' name='email' required='required' />
                            <label htmlFor='email'>{t('home.appointment.email')}</label>
                        </div>
                        <div className='group'>
                            <input placeholder='‎' type='tel' id='number' name='number' required='required' />
                            <label htmlFor='number'>{t('home.appointment.phone')}</label>
                        </div>
                        <button id='submitButton' className='btn' type='submit'>{t('home.appointment.submit')}</button>
                    </form>
                </div>
            </div>
        </section>
    )
}
export default Appointment