import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { LazyLoadImage } from "react-lazy-load-image-component";
import './Gallery.css'

function Gallery() {
  const media = [
    {alt: 'Blue Suit', url: require('./../../img/gallery/4.jpg')},
    {alt: 'Blue Suit', url: require('./../../img/gallery/5.jpg')},
    {alt: 'Blue Suit', url: require('./../../img/gallery/6.jpg')},
    {alt: 'Gray Suit', url: require('./../../img/gallery/10.jpg')},
    {alt: 'Gray Suit', url: require('./../../img/gallery/11.jpg')},
    {alt: 'Gray Suit', url: require('./../../img/gallery/12.jpg')},
    {alt: 'Red Suit', url: require('./../../img/gallery/13.jpg')},
    {alt: 'Red Suit', url: require('./../../img/gallery/14.jpg')},
    {alt: 'Red Suit', url: require('./../../img/gallery/15.jpg')},
    {alt: 'White Suit', url: require('./../../img/gallery/16.jpg')},
    {alt: 'White Suit', url: require('./../../img/gallery/17.jpg')},
    {alt: 'White Suit', url: require('./../../img/gallery/18.jpg')},
    {alt: 'Black Suit', url: require('./../../img/gallery/1.jpg')},
    {alt: 'Black Suit', url: require('./../../img/gallery/2.jpg')},
    {alt: 'Black Suit', url: require('./../../img/gallery/3.jpg')},
    {alt: 'Brown Suit', url: require('./../../img/gallery/7.jpg')},
    {alt: 'Brown Suit', url: require('./../../img/gallery/8.jpg')},
    {alt: 'Brown Suit', url: require('./../../img/gallery/9.jpg')},
  ]

  const [image, setImage] = useState(null)
  const [open, setOpen] = useState(false)

  const handleOpen = (file) => {
    setOpen(true)
    setImage(file)
  } 

  const handleClose = () => setOpen(false);

  const {t,i18n} = useTranslation()

  return (
    <div className='container gallery'>
      <h2 className='gallery-header'>{t('menu.gallery')}</h2>
      <p className='gallery-text'>{t('gallery.discover')}</p>
      <div className='gallery-line'></div>
      <div className='gallery-imgs'>
        {
          media.map((file, i) => (
            <div 
              key={i}
              onClick={()=>{handleOpen(file)}} 
              className='img-div'
            >
              <LazyLoadImage  src={file.url} alt={file.alt} className='gallery-img' /> 
            </div>
          ))
        }
      </div>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          className='modal-bg'
        >
          <Fade in={open}>
            <Box className='gallery-modal'>
              <img className='modal-img' src={image?.url} alt={image?.alt} />
              <i onClick={handleClose} className='fa-solid fa-x'></i>
            </Box>  
          </Fade>
        </Modal>
      </div>
    </div>
  );
}

export default Gallery