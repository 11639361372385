import React from 'react'
import FabricsIntro from './FabricsIntro'
import FabricsInfo from './FabricsInfo'

function Fabrics () {
  
  return (
    <div className='container py-5'>
      <FabricsIntro />
      <FabricsInfo />
    </div>
  )
}
export default Fabrics
