import { Link } from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import './Error404.css'

function Error404() {
    const {t,i18n} = useTranslation()

    return(
        <div className='error container'>    
             <h2 className='error-warning'>{t('error.h2')}</h2>
             <h3 className='error-msg'>{t('error.h3')}</h3>
             <Link className='backto-home' to="/">{t('error.link')} ›</Link>
        </div>
    )
}

export default Error404