import React from 'react'
import './Loader.css'

function Loader (){
  return (
    <div className="preloader-container">
        <div className='preloader'></div>
    </div>
  )
}
export default Loader;
