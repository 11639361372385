import {useTranslation} from 'react-i18next'
import './HomeTitle.css'

function HomeTitle() {
    const {t, i18n} = useTranslation()

    return (
        <section className='section1 py-3'>
            <h2 className='text-white text-left'>{t('home.h1')}</h2>
            <p className='text-white'>{t('home.p')}</p>
        </section>
    )
}

export default HomeTitle