import React, { useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import './Section2.css'
import video from '../../img/video.mp4'

function Section2() {
    const videoRef = useRef(null)
    const [isVideoInView, setIsVideoInView] = useState(false)
  
    // Use the useInView hook to track when the video is in the viewport
    const { ref, inView } = useInView({
      triggerOnce: true, // Only trigger once when the element comes into view
      threshold: 0.5, // Adjust this threshold value to control when the video starts playing (0.5 means when at least 50% of the video is visible)
    });
  
    // When the video comes into view, start playing it
    if (inView && !isVideoInView) {
      setIsVideoInView(true);
      videoRef.current.play();
    }
  
    // When the video goes out of view, pause it
    if (!inView && isVideoInView) {
      setIsVideoInView(false);
      videoRef.current.pause();
    }
    
    return (
        <section className='section2'>
            <video src={video} width='100%' height='100%' controls autoPlay loop muted playsInline preload='none' ref={videoRef} />
        </section>
    )
}

export default Section2