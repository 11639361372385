import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {useEffect, useState} from 'react'
import Header from './components/Header/Header'
import Stores from './components/Stores/Stores'
import Fabrics from './components/Fabrics/Fabrics.jsx'
import Gallery from './components/Gallery/Gallery'
import Home from './components/Home/Home'
import Footer from './components/Footer/Footer'
import ScrollToTop from './ScrollToTop'
import Error404 from './components/Error/Error404'
import Loader  from './components/Loader/Loader'
import './App.css'

function App() {
  const [showButton, setShowButton] = useState(false)


  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    })
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    })
  }

  let [loading, setLoading] = useState(false);
  useEffect(() => {
      setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 2000)
    
    
  }, [])
  if(loading){
    return <Loader />
  }
  
  return (
    <>
      
          <BrowserRouter >
            <Header />
              <Routes >
                <Route  path='/' element={<Home />} />
                <Route  path='fabrics' element={<Fabrics />}/>
                <Route  path='collections' element={<Gallery />}/>
                <Route  path='stores' element={<Stores />}/>
                <Route path='/*' element={<Error404 />}/>  
              </Routes>
            <Footer />
            {showButton && (
              <button onClick={scrollToTop} className='back-to-top'>
               <i className='fa-solid fa-chevron-up'></i>
              </button>
            )}
            
            <ScrollToTop  />
          </BrowserRouter>
        
    </>
  )
}

export default App