import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './FabricsInfo.css'

const fabrics = [
    {title:'Fratelli Tallia di Delfino', link:'https://www.tallia-delfino.com/'},
    {title:'Vitale Barberis Canonico', link:'https://vitalebarberiscanonico.com/'},
    {title:'Ariston Fabrics Napoli', link:'https://www.aristonfabrics.com/'},
    {title:'Holland & Sherry', link:'https://apparel.hollandandsherry.com/en'},
    {title:'Giovani Fabrics', link:'https://giovanifabrics.com/'},
    {title:'Loro Piana', link:'https://ii.loropiana.com/en/'},
    {title:'Dormeuil', link:'https://www.dormeuil.com/en/'},
    {title:'Carnet', link:'https://www.carnet.it/it/'},
]

function FabricsInfo() {
  const {t,i18n} = useTranslation()

  return (
    <section className='fabricsInfo'>
        <h2 className='text-center text-white my-5'>{t('fabrics.info')}</h2>
        <div className='fabrics-row'>
        {
            fabrics.map((item, i) => (
            <div className='fabrics' key={i} >
                <Link target='_blank' to={item.link}><i className='fa-solid fa-link mx-2'></i>{item.title}</Link>
            </div>
        ))}
        <div className='fabrics'></div>
        </div>
    </section>
  )
}

export default FabricsInfo