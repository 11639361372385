import React from 'react'
import './Stores.css'

function Stores(){
  return (
    <div className='stores'>
      <iframe  title='Mario Russo Map' src='https://www.google.com/maps/d/u/0/embed?mid=1829jomL_jglSlTbxauyZOct4DA9mrmU&ehbc=2E312F'></iframe>
    </div>
    )
}
export default Stores