import {useTranslation} from 'react-i18next'
import { Link } from 'react-router-dom'
import './BlueSection.css'
import blue1 from '../../img/suits/blue/10.jpg'
import blue2 from '../../img/suits/blue/11.jpg'
import blue3 from '../../img/suits/blue/12.jpg'
import blue4 from '../../img/suits/blue/4.jpg'
import blue5 from '../../img/suits/blue/7.jpg'

function BlueSection () {
    const {t,i18n} = useTranslation()

    return (
        <section className='blueSection'>
            <div>
                <div className='photo'>
                    <img src={blue4} alt='Suit' />
                </div>
                <div className='photoLink'>
                    <p>{t('home.discoverourfabrics')}</p>
                    <Link to='fabrics'>{t('home.explore')}</Link>
                </div>
                <div className='photo'>
                    <img src={blue5} alt='Suit' />
                </div>
            </div>
            <div>
                <div className='photo'>
                    <img src={blue1} alt='Suit' />
                </div>
                <div className='photo'>
                    <img src={blue2} alt='Suit' />
                </div>
                <div className='photo'>
                    <img src={blue3} alt='Suit' />
                </div>
            </div>
        </section>
    )
}

export default BlueSection